.project-navigation-tab-group {
  display: flex;
  flex-direction: column;
  user-select: none;
  background-color: rgba(248, 249, 250, 1);
}

/* Widths */
.project-navigation-section,
.project-navigation-section-selected,
.project-navigation-section-inactive,
.cost-planning-tab-item-selected,
.cost-planning-tab-item:hover,
.cost-planning-tab-item-selected:hover {
  width: 200px;
  font-size: 14px;
}

/* Project Navigation Sections */

.project-navigation-section {
  color: var(--confident);
}

.project-navigation-section-selected,
.project-navigation-section-selected .icon-container {
  color: var(--heritage);
}

.project-navigation-section-inactive {
  color: var(--confident);
  position: relative;
}

.project-navigation-section-inactive:hover,
.project-navigation-section-inactive:hover .icon-container {
  color: var(--heritage);
}

.project-navigation-section:hover,
.project-navigation-section-selected:hover,
.project-navigation-section:hover .icon-container {
  color: var(--heritage);
}

/* Tooltip Text */

.project-navigation-tab-link .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--heritage);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  /* Position the tooltip */
  border-radius: 100px;
  margin-left: 10px;
  position: absolute;
  z-index: 1;
}

.project-navigation-tab-link:hover {
  text-decoration: none;
}

.project-navigation-tab-link:hover .tooltiptext {
  visibility: visible;
}

/* Cost Planning */

.cost-planning-tab-item-selected {
  padding-left: 50px;
  cursor: pointer;
  color: var(--collaboration1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  font-size: 14px;
}

.cost-planning-tab-item-selected .cost-planning-tab-link-text {
  color: var(--heritage);
}

.cost-planning-tab-item {
  color: var(--collaboration1);
  padding-left: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  font-size: 14px;
}

.cost-planning-tab-item-selected:hover {
  padding-left: 50px;
  color: var(--collaboration1);
  width: 200px;
}

.cost-planning-tab-item-selected:hover .cost-planning-tab-link-text {
  color: var(--heritage);
  width: 200px;
}

.cost-planning-tab-item:hover {
  padding-left: 50px;
  color: var(--collaboration1);
  width: 200px;
}

.cost-planning-tab-item:hover .cost-planning-tab-link-text {
  color: var(--heritage);
  width: 200px;
}

.tab-link {
  color: white;
}

.tab-link:hover {
  text-decoration: none;
  color: white;
}

.cost-planning-tab-link {
  color: black;
}

.cost-planning-tab-link:hover {
  text-decoration: none;
  color: white;
}

.project-navigation-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-navigation-label .front-icon {
  display: flex;
  flex-direction: row;
}

.icon-container {
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: var(--collaboration2);
}
