.page-background {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0 auto;
}

.left-nav-pane {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  justify-content: space-between;
}

.left-nav-pane,
.left-nav-pane-backer {
  background-color: rgba(248, 249, 250, 1);

  width: 215px;
  height: 815px;
  margin-top: -15px;

  margin-right: 30px;
  border-radius: 0 0 50px 0;
}

.left-nav-pane-backer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.left-nav-pane-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 215px;
  height: 820px;
}

.left-nav-pane-title {
  display: flex;
  flex-direction: column;

  margin-bottom: 8px;
}

.left-nav-pane-title > .display-4 {
  font-size: 30px;
}

.page-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 0 82.5px;
}

.page-content-center {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  width: 1460px;
  min-height: 760px;
  justify-content: space-around;
}

.left-nav-pane-content .project-control-panel h1.display-4 {
  font-size: 20px;
}

.left-nav-pane-content .project-control-drawer {
  display: flex;
  align-items: center;
}

.left-nav-pane-content .project-control-drawer.separator-after {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--collaboration3);
  margin-bottom: 20px;
}

.left-nav-pane-content .project-control-drawer > button {
  margin-bottom: 15px;
}

.left-nav-pane-content .project-control-drawer > button.hidden {
  display: none;
}

.left-nav-pane-content .project-control-drawer > button.reset {
  background-color: var(--strength1);
}

.left-nav-pane-content .project-control-drawer > button.action {
  background-color: var(--natural1);
}

.left-nav-pane-content .project-control-drawer > button.action-secondary {
  background-color: var(--heritage);
}

.left-nav-pane-content .project-control-drawer > button.unavailable {
  background-color: var(--collaboration3);
  color: var(--collaboration1);
}

.left-nav-pane-content .project-control-panel-button ~ h1.display-4 {
  font-size: 15px;
  margin-bottom: 0px;
  margin-left: 10px;
}

button.project-control-panel-button {
  border: none;
  outline: none;
}
