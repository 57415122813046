/* Payment Reconciliation */

.pcr-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.pcr-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid lightgrey 1px;
}

.pcr-row-subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: solid lightgrey 1px;

  color: var(--confident);
  background: linear-gradient(
    0deg,
    rgb(254, 249, 255) 0%,
    rgb(219, 215, 223) 100%
  );
}

.pcr-row-subtotal .pcr-description-cell {
  border-right: none;
}

.pcr-row-subtotal .pcr-value-cell {
  border-right: none;
}

.pcr-row-total .pcr-description-cell {
  border-right: none;
}

.pcr-row-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: var(--integrity);
  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );
}

.pcr-title-cell1 {
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;

  border-right: solid lightgrey 1px;
  padding: 5px;
}

.pcr-title-cell2 {
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;

  border-right: solid lightgrey 1px;
  padding: 5px;
}

.pcr-description-cell {
  width: 200px;
  display: flex;
  flex-direction: row;

  border-right: solid lightgrey 1px;

  padding: 5px;
}

.pcr-value-cell {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: solid lightgrey 1px;

  padding: 5px;
}
