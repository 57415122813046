.benchmark-project-info-container {
    padding-top: 30px;
    z-index: 1;
}

.titled-table-heading {
    margin: 10px;
}

.create-benchmark-form-container {
    display: flex;
    flex-direction: row;
}

.create-project-panel {
    height: auto;
}

/* Benchmark Project View Related  Table Css */
.project-info-tab1-table-cell-text {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    color: black;
    text-align: left;
    padding: 7px
}

.project-info-table-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.col {
    width: 10%;
    text-align: center;
}

.col2 {
    width: 45%;
}

.col3 {
    width: 45%;
}

.create-project-matrix-panel {
    scrollbar-width: thin;
}
.custom-margin{
    margin-right:150px;
    margin-left:150px;
}