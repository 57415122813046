.project-control-features-save {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: var(--integrity);

  width: 150px;
  height: 35px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-bottom: 30px;
  margin-top: 30px;
}

.project-control-features-save:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.project-control-features {
  width: 350px;

  padding: 15px;
}

.project-control-settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 25px;
}

.project-control-settings-label {
  width: 150px;
}
