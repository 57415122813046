.cost-report-trend-chart-container {
  width: 500px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.cost-report-trend-chart {
  min-width: 500px;
  height: 300px;
  padding: 20px;
}

.cost-report-trend-chart-legend {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cost-report-trend-chart-legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 1px lightgrey solid;
}

.cost-report-trend-chart-legend-item-bar {
  width: 50px;
  height: 7px;
  background-color: orange;
  border-radius: 50px;
}

.cost-report-trend-chart-legend-item-text {
  width: 130px;
  font-size: 11px;
}
