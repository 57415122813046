/* Payment Recommendation Box */

.pcpr-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.pcpr-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  border-bottom: solid lightgrey 1px;
}

.pcpr-row-subtotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  border-bottom: solid lightgrey 1px;

  color: var(--confident);
  background: linear-gradient(
    0deg,
    rgb(254, 249, 255) 0%,
    rgb(219, 215, 223) 100%
  );
}

.pcpr-row-subtotal .pcpr-description-cell {
  border-right: none;
}

.pcpr-row-total .pcpr-description-cell {
  border-right: none;
}

.pcpr-row-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: white;

  color: var(--integrity);
  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );
}

.pcpr-description-cell {
  width: 360px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: solid lightgrey 1px;

  padding: 5px;
}

.pcpr-value-cell {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 5px;
}
