.cost-plan-stage-selector {
  height: 40px;
}

.cost-plan-stage-selector-bar {
  display: flex;
  flex-flow: wrap;

  align-items: center;
}

.cost-plan-stage-selector-box-inactive,
.cost-plan-stage-selector-box,
.cost-plan-stage-selector-current-stage-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(235, 235, 235);
  height: 40px;
  width: 40px;
  margin-left: 20px;
  color: white;
  border-radius: 100px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.cost-plan-stage-selector-box {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration1);
  user-select: none;
}

.cost-plan-stage-selector-box:hover {
  box-shadow: 0 4px 8px 0 var(--strength2), 0 6px 20px 0 var(--strength2);
  background-color: var(--heritage);
  cursor: pointer;
}

.cost-plan-stage-selector-current-stage-box {
  box-shadow: 0 4px 8px 0 var(--strength2), 0 6px 20px 0 var(--strength2);
  background-color: var(--heritage);
  cursor: pointer;
  user-select: none;
}

.cost-plan-stage-selector-box-inactive {
  background-color: var(--collaboration3);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--confident);
}

.cost-plan-stage-selector-label {
  font-size: 25px;
}
