.pcpo-chart-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.pcpo-chart-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 266px;

  margin-top: 115px;
  margin-left: 24px;
}

.pcpo-chart-legend {
  display: flex;
  flex-direction: column;

  margin-top: 30px;
}

.pcpo-chart-total-label,
.pcpo-chart-total-value {
  font-size: 10px;
  letter-spacing: 0.5px;
}

.pcpo-chart-legend .legend-entry-name {
  font-size: 10px !important;
}

.pcpo-chart-legend .legend-entry-value {
  font-size: 10px !important;
}
