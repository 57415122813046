.modal-show {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-hide {
  display: none;
}

.modal-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: var(--collaboration1);
  color: white;
  font-size: 16px;

  height: 30px;
  width: 100%;
}

.modal-close-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 30px;
  width: 30px;
  background-color: var(--collaboration2);
}

.modal-close-button:hover {
  cursor: pointer;
  background-color: var(--strength1);
}

.modal-title {
  margin-left: 8px;
}