.pctc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  margin-bottom: 30px;
  margin-top: 30px;
}

.pctc-visual {
  margin-right: 30px;
}
