.bocc-percent-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .bocc-percent {
    color: var(--heritage);
    font-size: 20px;
  
    margin-right: 10px;
  margin-left:25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .bocc-text {
    font-size: 14px;
  }
  .bocc{
      margin-top: 10px;
      margin-bottom: 10px;
  }