.crfcs-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;

  font-size: 11px;
}

.crfcs-row {
  display: flex;
  flex-direction: row;
  border-bottom: solid lightgrey 1px;

  min-height: 30px;
}

.crfcs-title-row {
  display: flex;
  flex-direction: row;
  border-bottom: solid lightgrey 1px;

  min-height: 30px;
}

.crfcs-title-row .crfcs-description-cell {
  background-color: var(--integrity);
}

.crfcs-total-row {
  display: flex;
  flex-direction: row;
  color: var(--integrity);

  min-height: 30px;

  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );
}

.crfcs-total-row .crfcs-description-cell {
  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );

  border-right: none;
  border-bottom: none;
}

.crfcs-description-cell {
  background-color: var(--collaboration4);
  border-right: solid lightgrey 1px;

  width: 207.5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 30px;

  padding: 3px;
}

.crfcs-value-cell-heading {
  min-height: 30px;
  border-right: solid lightgrey 1px;

  width: 97.5px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.crfcs-value-cell {
  min-height: 30px;

  width: 97.5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 3px;
}
