.edit-profile-container {
  max-height: 800px;
  font-size: 14px;
  padding: 10px;
  overflow-y: auto;
}

.create-profile-form-container .edit-profile-container {
  max-height: 1000px;
  font-size: 14px;
  padding: 10px;
  overflow-y: auto;
}

.edit-profile-form-container {
  padding-top: 10px;
  padding-bottom: 1px;
  margin: 0 auto;
}

.edit-profile-heading {
  text-align: center;
}

.edit-profile-icon {
  margin-right: 30px;
  padding-top: 5px;
  font-size: 10px;
  width: 10px;
  color: var(--collaboration2);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.edit-profile-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
}

.edit-profile-inputs-container {
  display: flex;
  flex-direction: column;
}

.edit-profile-inputs-row {
  display: flex;
  flex-direction: row;
}

.edit-profile-inputs-row-container {
  display: flex;
  flex-direction: column;
}

.edit-profile-input {
  width: 300px;
  height: 25px;
  margin-top: 3px;
}

.edit-profile-select {
  width: 300px;
  height: 25px;
  margin-top: 3px;
}

.edit-profile-input-error {
  width: 300px;
  margin-bottom: 10px;
  border-color: rgb(255, 0, 0);
}

.edit-profile-titles-container {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.edit-profile-image-preview {
  pointer-events: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 100px;
  max-height: 100px;
}

.edit-profile-image-preview-block {
  width: 100px;
  height: 100px;
  margin-left: 30px;
}

.edit-profile-title {
  height: 31px;
  width: 125px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.edit-profile-title .display-4 {
  margin: 0px;
}

.edit-profile-error {
  margin-left: 5px;
  margin-bottom: 5px;

  color: var(--heritage);
}

.edit-profile-submit-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  margin-bottom: 15px;
}

.edit-profile-submit {
  display: flex;
  flex-direction: row;
  justify-content: center;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--natural1);
  color: white;
  padding: 5px;
  width: 150px;
  height: 30px;
}

.edit-profile-submit:hover {
  background-color: var(--heritage);
  cursor: pointer;
}

.edit-profile-privacy-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
}

.edit-profile-privacy-row-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.edit-profile-privacy-row .checkboxes-vertical-container {
  width: 615px;
  font-size: 14px;
}

.edit-profile-delete-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.edit-profile-delete-container .edit-profile-delete-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  background-color: var(--heritage);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 13px;

  width: 150px;
  height: 30px;
  text-decoration: none;
  user-select: none;
}

.edit-profile-delete-container .edit-profile-delete-button:hover {
  background-color: var(--collaboration1);
}

.edit-profile-global-privacy {
  margin-bottom: 15px;
}
