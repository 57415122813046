/* Payment Details Box */

.pcpd-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin-top: 10px;
}

.pcpd-column {
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 100%;
}

.pcpd-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid lightgrey 1px;
}

.pcpd-description {
  border-right: solid lightgrey 1px;
  padding: 5px;

  width: 250px;
}

.pcpd-value {
  padding: 5px;

  width: 250px;
}
