.pccts-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 11px;
}

.pccts-row {
  display: flex;
  flex-direction: row;
  border-bottom: solid lightgrey 1px;
  height: 30px;
}

.pccts-total-row {
  display: flex;
  flex-direction: row;
  border-bottom: solid lightgrey 1px;
  background-color: var(--collaboration4);
  height: 30px;
}

.pccts-description-cell {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 3px;
}

.pccts-value-cell-heading {
  height: 30px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-left: solid lightgrey 1px;
}

.pccts-value-cell {
  height: 30px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-left: solid lightgrey 1px;
  align-items: center;
  padding: 3px;
}

.pccts-count-cell {
  height: 30px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-left: solid lightgrey 1px;
  align-items: center;
  padding: 3px;
}
