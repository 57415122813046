.cost-report-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 30px;
  margin-right: 30px;
  font-size: 11px;
  width: 1560px;
}

/* Heading */

.cost-report-table-heading-row {
  display: flex;
  flex-direction: row;
  background-color: black;
  color: var(--integrity);
  /* font-weight: bold; */
}

.cost-report-table-cell-heading-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 210px;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
}

.cost-report-table-cell-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: 75px;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  background-color: var(--collaboration1);
  padding: 5px;
  color: white;
}

.cost-report-table-cell-group-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
}

.group-heading-width-2 {
  width: 150px;
}
.group-heading-width-3 {
  width: 225px;
}
.group-heading-width-4 {
  width: 300px;
}

.cost-report-table-cell-heading-group-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 210px;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
}

.cost-report-table-cell-group-total-heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
}
/* Cells */

.cost-report-table-row-link {
  display: flex;
  flex-direction: row;

  background-color: var(--integrity);
  color: var(--confident);

  transition: all 0s cubic-bezier(0.25, 0.8, 0.25, 1);
  text-decoration: none;
  border-right: solid black 1px;
}

.cost-report-table-row-link:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(223, 223, 223) !important;
  color: var(--confident);

  cursor: pointer;
  text-decoration: none;

  -webkit-transform: scale(1.003);
  -ms-transform: scale(1.003);
  transform: scale(1.003);
}

.cost-report-table-row {
  display: flex;
  flex-direction: row;

  color: var(--confident);

  height: 40px;

  user-select: none;
  background-color: var(--integrity);
  border-right: solid black 1px;
}

.cost-report-table-row-subtotal-white {
  color: white;
}

.cost-report-table-cell-description {
  flex-direction: row;
  width: 210px;
  border-bottom: solid black 1px;
  border-left: solid black 1px;

  padding: 5px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.description-white {
  color: white;
}

.cost-report-table-cell-description-gap {
  /* width: 100%; */
}

.cost-report-table-cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 75px;
  border-bottom: solid black 1px;
  border-left: solid black 1px;

  padding: 5px;
  transition: width 0px 5s;
}

.cost-report-table-shaded-cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 75px;
  border-left: solid black 1px;
  border-bottom: solid black 1px;
  background: linear-gradient(135deg, #fcddcb 0%, #fcddcb 100%);
  padding: 5px;
}

.cost-report-table-darkshaded-cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 75px;
  border-bottom: solid black 1px;
  border-left: solid black 1px;
  background:  linear-gradient(135deg, #f49d68 100%, #f49d68 100%);
  padding: 5px;
  color: white;
  font-weight: bold;
}

/* Gap */

.cost-report-table-row-gap {
  display: flex;
  flex-direction: row;
  /* background-color: var(--collaboration2); */
  background-color: white;
  border-right: solid black 1px;
  height: 40px;
  color: black;
}

/* Total */

.cost-report-table-row-subtotal {
  display: flex;
  flex-direction: row;
  border-right: solid black 1px;
  background: linear-gradient(135deg, #f49d68 100%, #f49d68 100%);
  font-weight: bold;

  height: 40px;
  color: white;
}

.cost-report-table-row-dark{
  background:var(--heritage);
}

.cost-report-table-row-total {
  display: flex;
  flex-direction: row;
  background-color: var(--collaboration1);
  border-right: solid black 1px;
  height: 40px;
  color: white;
  font-weight:bold;
}

.cost-report-table-cell,
.cost-report-table-cell-description {
  /* border-bottom: solid lightgrey 1px;
  border-right: solid lightgrey 1px; */
}

.estimate-summary-table-total-row .estimate-summary-table-description-cell,
.estimate-summary-table-total-row .estimate-summary-table-description-cell-ref,
.estimate-summary-table-total-row .estimate-summary-table-quantity-cell,
.estimate-summary-table-total-row .estimate-summary-table-unit-cell,
.estimate-summary-table-total-row .estimate-summary-table-rate-cell,
.estimate-summary-table-total-row .estimate-summary-table-subtotal-cell {
  border-bottom: none;
}

.cost-report-table-cell-history-button {
  justify-content: flex-end;
}

.cost-report-table-cell-heading-group-description-showhistory-false {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 218px;
  border-bottom: solid black 1px;
  border-left: solid black 1px;
  background-color: var(--collaboration1);
  color: white;
  padding: 5px;
}

.group-heading-width-3-showhistory-false {
  width: 252px;
}

.gap-cells-style {
  border-left: none;
  background: white;
}
