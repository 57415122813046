.benchmarking-chart-box-dashboard {
  width: 500px;
  height: 300px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding: 10px;
  padding-top: 1px;
}

.benchmarking-chart-box-dashboard-hidden {
  width: 500px;
  height: 300px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration4);
  padding: 10px;
  padding-top: 1px;
}

.benchmarking-chart-box-dashboard-disabled {
  display: none;
}

.benchmarking-chart-container {
  height: 250px;
  padding: 10px;
}

.benchmarking-chart-box {
  width: 455px;
  height: 300px;
  margin-bottom: 30px;
  margin-right: 30px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding: 10px;
  padding-top: 1px;
}

.benchmarking-chart-box-hidden {
  width: 455px;
  height: 300px;
  margin-bottom: 30px;
  margin-right: 30px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration4);
  padding: 10px;
  padding-top: 1px;
}

.benchmarking-chart-box-disabled {
  display: none;
}

.benchmarking-donut-chart-heading {
  display: flex;
  justify-content: space-between;
}

.legend-container {
  width: 100%;
  height: 20px;
  display: flex;
}

.benchmarking-chart-heading {
  display: flex;
  justify-content: space-between;
}

.benchmarking-speedometer-box {
  width: 435px;
  height: 300px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding-top: 3px;
  padding-left: 3px;
}

.benchmarking-chart-box-superelements {
  width: 900px;
  min-height: 300px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  padding-top: 1px;
}

.benchmarking-chart-box-costm2 {
  width: 900px;
  height: 300px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}

.benchmarking-chart-box-costm2-onefourth {
  width: 500px;
  height: 400px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}
.benchmarking-chart-box-costm2-half {
  width: 930px;
  height: 400px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}

.metrics-graph-box {
  width: 1500px;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}

.benchmark-doughnut-chart-box {
  width: 435px;
  height: 310px;
  padding-left: 10px;
  padding-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  margin-bottom: 30px;
  margin-left: 30px;
  display: inline-block;
}

.dashboard-highlights-grid {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.benchmarking-table-row {
  display: flex;
  flex-direction: row;
  width: 1500px;
  right: 30px;
}

.benchmark-subaccordion {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--collaboration2);
  color: white;
  padding: 7px;
  width: 1430px;
  height: 36px;
  margin-bottom: 30px;

  border: none;
  text-align: center;
  font-size: 20px;

  margin-right: 30px;
}

.bench-manager-comment {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--integrity);
  padding: 10px;
  width: 1430px;
}

.bench-commentary-flex-box {
  display: flex;
  width: 1560px;
}

.metrics-title {
  margin-top: -15px;
  margin-bottom: 20px;
}

.section-title {
  margin-bottom: 10px;
}

.section-title .display-4 {
  margin-bottom: 30px;
  font-size: 30px;
}

.benchmarking-dashboard-title-container .display-4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.benchmarking-dashboard-title-container {
  display: flex;
  flex-direction: row;
  width: 500px;
}

.benchmark-section-empty {
  margin-bottom: 30px;
}

.benchmarking-on-cost-comparison-title {
  text-overflow: ellipsis;
}
