.cost-report-critical-variations {
  display: flex;
  flex-direction: column;
}

.cost-report-critical-variations-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 11px;

  border-bottom: 1px lightgrey solid;
}

.cost-report-critical-variations-symbol-red {
  box-shadow: 0 4px 8px 0 var(--strength1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 5px;
  width: 5px;
  margin-right: 15px;
  margin-left: 15px;

  background-color: var(--strength1);

  border-radius: 100px;
}

.cost-report-critical-variations-ref {
  width: 40px;
}

.cost-report-critical-variations-description {
  display: flex;
  align-items: center;

  width: 300px;
  border-right: 1px lightgrey solid;
  border-left: 1px lightgrey solid;
  min-height: 40px;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
}

.cost-report-critical-variations-value {
  width: 100px;

  display: flex;
  justify-content: flex-end;
}
