.post-contract-consultant-fees-control {
  display: flex;
  flex-direction: row;

  height: max-content;
  width: max-content;
  padding: 20px;

  background-color: rgba(248, 249, 250, 1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
