/* CONTAINER */

.retention-status {
        width: 500px;
      
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      
        transition: transform 0.3s;
  }

  .retention-status-table{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

.retention-status-row-total{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  
    height: 40px;
    width: 100%;
}

.retention-status-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  
    height: 40px;
    width: 100%;
  
    border-bottom: 1px lightgrey solid;
}

.retention-status-label{
    font-size: 13px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height:40px;
   width: 80%;
    color: var(--confident);
    padding-left: 15px;
    border-right:1px lightgrey solid ;
}

.retention-status-value{
    font-size: 13px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: right;
  
    width: 20%;
    padding-right: 15px;
}
