.post-contract-top-row {
  height: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1560px;
}

.post-contract-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  justify-content: space-between;
}

.post-contract-column,
.post-contract-column-backer {
  background-color: rgba(248, 249, 250, 1);

  width: 215px;
  height: 800px;
  margin-top: -15px;

  margin-right: 30px;
  border-radius: 0 0 50px 0;
}

.post-contract-column-backer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
