.benchmark-page {
  color: black;

  width: 1700px;
  height: 600px;

  display: flex;
  flex-direction: row;
  align-items: space-between;

  overflow: scroll;
}

.benchmark-page-elements {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.benchmark-page-element {
  display: flex;
  flex-direction: row;
  align-items: space-between;

  border-bottom: 1px solid black;
}

.benchmark-page-code {
  width: 100px;
  border-right: 1px solid black;
  padding: 5px;
}

.benchmark-page-description {
  width: 500px;
  border-right: 1px solid black;
  padding: 5px;
}

.benchmark-page-quantity {
  width: 150px;
  border-right: 1px solid black;
  padding: 5px;
}
.benchmark-page-rate {
  width: 150px;
  border-right: 1px solid black;
  padding: 5px;
}
.benchmark-page-total {
  width: 150px;
  border-right: 1px solid black;
  padding: 5px;
}
.benchmark-page-unit {
  width: 50px;
  border-right: 1px solid black;
  padding: 5px;
}
