/* CONTAINER */

.provisional-sum-status {
  width: 500px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  transition: transform 0.3s;
}

.provisional-sum-status-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* TABLE */

.provisional-sum-status-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/* TABLE ROW */

.provisional-sum-status-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: 40px;
  width: 100%;

  border-bottom: 1px lightgrey solid;
}

.provisional-sum-status-row-total {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: 40px;
  width: 100%;
}

/* LABEL */

.provisional-sum-status-label {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 165px;
  height: 40px;
  color: var(--confident);
  padding-left: 15px;
}

.provisional-sum-status-count {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 165px;
  height: 40px;

  border-left: 1px lightgrey solid;
  border-right: 1px lightgrey solid;
}

.provisional-sum-status-value {
  font-size: 13px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 165px;
  height: 40px;
}

.provisional-sum-status-percentage {
  color: var(--fluidity1);
  margin-right: 5px;
  font-size: 13px;
}

.provisional-sum-status-overall {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 60px;
}

.provisional-sum-status-overall-approved {
  margin-left: 5px;
  color: var(--natural1);
}

/* Traffic Lights */

.provisional-sum-status-symbol-red {
  box-shadow: 0 4px 8px 0 var(--heritage), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 5px;
  width: 5px;
  margin-right: 15px;
  background-color: var(--heritage);

  border-radius: 100px;
}

.provisional-sum-status-symbol-yellow {
  box-shadow: 0 4px 8px 0 var(--built1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 5px;
  width: 5px;
  margin-right: 15px;

  background-color: var(--built1);

  border-radius: 100px;
}

.provisional-sum-status-symbol-green {
  box-shadow: 0 4px 8px 0 var(--natural1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 5px;
  width: 5px;
  margin-right: 15px;

  background-color: var(--natural1);

  border-radius: 100px;
}

.provisional-sum-status-symbol-total {
  height: 15px;
  width: 15px;
  margin-right: 15px;
}
