/* Table */
.pct-container {
  display: flex;
  font-size: 11px;

  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: fit-content;
}

.pct-spacer {
  margin-top: 30px;
}

.pct-title {
  color: var(--integrity);
  padding: 5px;
  overflow-x: visible;
}

.pct-title .display-4 {
  font-size: 20px;
}

.pct {
  background-color: var(--fluidity1);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1560px;
  overflow-x: scroll;
  overflow-y: hidden;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
}

/* Row Containers */
.pct-row,
.pct-row-total,
.pct-row2,
.pct-row-page-total,
.pct-row-selected {
  background-color: rgba(248, 249, 250, 1);
  width: max-content;
  height: 30px;
}

.pct-headings-container {
  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: max-content;
}

.pct-headings-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: 50px;
}

.pct-filter-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  height: 20px;
}

.pct-row-selected,
.pct-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid rgb(228, 228, 228);
}

.pct-row-selected:hover,
.pct-row:hover {
  background-color: rgb(223, 223, 223);
  color: var(--confident);
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pct-row2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.pct-row-page-total {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--collaboration4);
  color: white;
}

.pct-row-total {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--collaboration4);

  color: var(--integrity);
}
