.benchmarking-table-anonymity {
  width: 50px;
  display: static;
  margin-right: -65px;
  margin-left: 15px;
  /* vvvvv MUST EQUAL HEIGHT OF TABLE HEADING */
  margin-top: 47px;
  /* ^^^^^ */
  margin-bottom: 30px;
}

.toggle-wrapper {
  display: flex;
  flex-direction: row;

  width: 200px;
}

.toggle-hidden-name {
  width: 150px;
  overflow: hidden;
  height: 29px;
  display: flex;
  flex-direction: row;
  padding-top: 3px;
}

.benchmarking-table-anonymity .toggle-container {
  height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 50px;
  overflow: hidden;
}

.benchmarking-table-anonymity .table-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.benchmarking-table-anonymity .table-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.benchmarking-table-anonymity .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--collaboration2);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.benchmarking-table-anonymity .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--heritage);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--heritage);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.benchmarking-table-anonymity .slider.round {
  border-radius: 34px;
}

.benchmarking-table-anonymity .slider.round:before {
  border-radius: 50%;
}

/* Tooltip Text */

.benchmarking-table-anonymity .table-switch .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--collaboration2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  text-align: center;
  border-radius: 100px;

  /* Position the tooltip */
  position: absolute;
  right: 55px;
  z-index: 1;
  margin-top: -3px;
  user-select: none;
}

.benchmarking-table-anonymity .table-switch:hover .tooltiptext {
  visibility: visible;
}

.benchmarking-table-anonymity .table-switch .tooltiptext-visible {
  visibility: hidden;
  width: 120px;
  background-color: var(--heritage);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #fff;
  text-align: center;
  border-radius: 100px;

  /* Position the tooltip */
  position: absolute;
  right: 55px;
  z-index: 1;
  margin-top: -3px;
  user-select: none;
}

.benchmarking-table-anonymity .table-switch:hover .tooltiptext-visible {
  visibility: visible;
}
