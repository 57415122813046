.correspondence-open {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.correspondence-open.loading {
  color: var(--collaboration2);
  cursor: not-allowed;
}

.correspondence-open:hover {
  color: var(--heritage);
  cursor: pointer;
}

.correspondence-icon {
  margin-right: 6px;
  font-size: 16px;
  color: var(--collaboration2);
}

.correspondence-open:hover .correspondence-icon {
  color: var(--heritage);
}

.correspondence-modal-wrapper {
  display: flex;
  flex-direction: row;
}

.correspondence-modal-wrapper p.message {
  color: var(--heritage);
}

.correspondence-left-nav {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  width: 300px;
  font-size: 13px;
  overflow-y: auto;
  max-height: 700px;
}

.correspondence-projects-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 13px;
  overflow-y: auto;
  height: 650px;
  border-bottom: 1px dashed var(--heritage);
  margin-bottom: auto;
}

.correspondence-projects-list-section {
  --section-color: var(--heritage);
}

.correspondence-projects-list-section.closed-out {
  --section-color: var(--collaboration1);
}

.correspondence-projects-list .correspondence-projects-list-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.correspondence-projects-list-section .list-section-heading {
  padding-top: 5px;
  padding-left: 15px;
  color: var(--section-color);
}

.correspondence-projects-list .correspondence-project {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--collaboration1);
  padding: 0 10px;
  border-left: 5px solid var(--section-color);
}

.correspondence-projects-list .correspondence-project:hover,
.correspondence-projects-list .correspondence-project:active,
.correspondence-projects-list .correspondence-project.selected {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  cursor: pointer;
  background-color: var(--collaboration4);
  padding-left: 10px;
}

.correspondence-projects-list .correspondence-projects-list-item {
  padding: 15px 0;
  padding-left: 15px;
}

.correspondence-project .project-management-avatar {
  margin-top: 0px;
}

.correspondence-projects-list .project-item-notification {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.correspondence-projects-list .project-item-notification-text {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.correspondence-project-comments-loading {
  min-height: 700px;
  width: 1050px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.correspondence-project-comments {
  min-height: 700px;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 1035px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.correspondence-estimate-container {
  padding-left: 15px;
}

.correspondence-comments-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 930px;
  color: var(--confident);
  border-bottom: 1px dashed var(--collaboration3);
  padding-bottom: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
}

.correspondence-project-title {
  font-size: 30px;
  margin: 30px 0;
  margin-right: auto;
}

.correspondence-estimate-title {
  font-size: 20px;
  color: var(--collaboration1);
}

.correspondence-right-pane {
  width: 1050px;
}

.correspondence-right-pane > .correspondence-project-not-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
  color: var(--collaboration1);
  padding-left: 10px;
}
.correspondence-right-pane > .correspondence-project-not-selected > i {
  color: var(--collaboration4);
  padding-bottom: 20px;
}

.correspondence-project-comments .ebt-filtered {
  overflow-y: visible;
  padding-left: 0;
}
