.post-contract-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;

  height: 30px;

  margin-top: 15px;
  margin-bottom: 15px;
}
.pc-toggle-active-button {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  color: white;
  background-color: var(--heritage);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 420px;
  height: 40px;

  user-select: none;
}
.pc-toggle-active-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
}
.pc-toggle-inactive-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: black;
  background-color: white;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-size: 15px;

  width: 420px;
  height: 40px;

  user-select: none;
}

.pc-toggle-inactive-button:hover {
  background-color: var(--collaboration1);
  cursor: pointer;
  color: white;
}
.post-contract-clarifications {
  padding-bottom: 1px !important;
}
