/* Table */

.crct {
  display: flex;
  flex-direction: row;

  font-size: 12px;
}

.crct-table {
  width: 300px;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-shrink: 2;
}

/* Description Column */

.crct-col-description {
  display: flex;
  flex-direction: column;
}

.crct-description-col {
  background-color: rgb(236, 236, 236);
  width: 202px;
}

.crct-description-col-row {
  height: 30px;

  border-bottom: 1px lightgrey solid;
  border-right: 1px lightgrey solid;
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.crct-dd {
  height: 45px;
}
.crct-description-col-row-title {
  height: 30px;
  line-height: 30px;

  /* width: 202px; */
  border-bottom: 1px lightgrey solid;
  border-right: 1px lightgrey solid;
  padding-left: 5px;
  padding-right: 5px;
  background-color: var(--integrity);
}

.crct-description-col-row-total {
  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );
  color: var(--integrity);

  padding-right: 5px;
  border-bottom: 1px lightgrey solid;

  height: 30px;
  padding-left: 5px;
  padding-bottom: 6px;
  padding-top: 5px;
  flex-direction: row;
  align-items: center;
}

/* Content Column */

.crct-col {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
}

.crct-col-row {
  display: flex;
  flex-direction: column;
  text-align: right;

  height: 30px;

  /* width: 149px; */
  border-bottom: 1px lightgrey solid;
  padding: 5px;
}

.crct-col-row-title {
  display: flex;
  flex-direction: column;
  text-align: right;

  height: 30px;
  line-height: 30px;

  width: 149px;
  border-bottom: 1px lightgrey solid;
  padding-left: 5px;
  padding-right: 5px;
}

.crct-col-row-total {
  height: 30px;
  line-height: 30px;

  text-align: right;
  background: linear-gradient(
    0deg,
    rgb(145, 143, 146) 0%,
    rgb(85, 87, 90) 100%
  );
  color: var(--integrity);
  border-bottom: 1px lightgrey solid;

  padding-left: 5px;
  padding-right: 5px;
}
