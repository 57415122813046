.accordion-description {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 45px;
  border-right: 1px solid white;
}

.accordion-container {
  width: 1430px;
}

.accordion-button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--fluidity1);
  color: var(--integrity);

  width: 1430px;
  height: 45px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  text-align: center;
  align-items: center;

  font-size: 16px;
  user-select: none;
  transition: 0.25s ease-in-out;

  margin-bottom: 30px;
}

.accordion-button.total-row {
  background-color: var(--collaboration1);
}

.accordion-button:hover {
  background-color: rgb(0, 141, 192);
  text-decoration: none;
  color: white;
}

.accordion-button.total-row:hover {
  background-color: var(--collaboration2);
}

.accordion-label {
  margin-left: 15px;
  float: left;
}

.accordion-rates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accordion-local-region-area {
  width: 100px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-right: 1px solid white;
}

.accordion-unit {
  width: 65px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-right: 1px solid white;
}

.accordion-rate {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-right: 1px solid white;
}

.accordion-line {
  height: 45px;
  width: 1px;
  background-color: var(--integrity);

  margin-right: 30px;
  margin-left: 30px;
}

.accordion-number {
  margin-top: 7px;
}

.accordion-costs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accordion-icon {
  margin-right: 10px;
  opacity: 0.7;
  height: 20px;
  width: 20px;
}

.accordion-total {
  width: 125px;
  height: 45px;

  padding-right: 15px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
