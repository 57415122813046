/* DASHBOARD */

.project-dashboard-area {
  width: 1560px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-dashboard-charts-container1 {
  width: 630px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 15px;
}

.project-dashboard-charts-container2 {
  width: 630px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.risk-opportunity-container {
  height: 135px;
  width: 630px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  padding-top: 1px;
  margin-bottom: 30px;
}

.scope-design-changes-box {
  height: 300px;
  width: 630px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
  padding-top: 1px;

  margin-bottom: 15px;
}

.cost-plan-comparison-dashboard-box {
  height: 300px;
  width: 630px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: rgba(248, 249, 250, 1);
}
