.dashboard {
  width: 1620px;
  flex-flow: wrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.dashboard-column {
  width: 500px;
  flex-direction: column;
  margin-right: 30px;
  position: relative;
}

.dashboard-panel {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 500px;
  /* background-color: rgba(248, 249, 250, 1); */
  background-color: var(--integrity);

  height: max-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-title {
  color: var(--collaboration1);
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  z-index: 1000;

  /* Dark Heading */
  /* color: var(--integrity);
  background: linear-gradient(
    135deg,
    rgb(85, 87, 90) 0%,
    rgb(145, 143, 146) 100%
  ); */
}

.dashboard-title .display-4 {
  font-size: 15px;
  margin-bottom: 0px;
}

.dashboard-slot {
  height: 30px;
  width: 500px;
  text-decoration: none;
  color: transparent;
  transition: 0.5s ease all;
}

.dashboard-title:hover {
  cursor: pointer;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--integrity);
  background: linear-gradient(
    135deg,
    rgb(228, 97, 15) 0%,
    rgb(228, 144, 92) 100%
  );
}

.dashboard-slot-highlight {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 60px;
  width: 500px;
  /* background: linear-gradient(
      135deg,
      rgb(50, 98, 149) 0%,
      rgb(101, 137, 176) 100%
    ); */
  background-color: rgba(50, 98, 149, 0.5);
  transition: 0.5s ease all;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}

.dashboard-title-highlight {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--integrity);
  background: linear-gradient(
    135deg,
    rgb(50, 98, 149) 0%,
    rgb(101, 137, 176) 100%
  );
}

.dashboard-panel-hidden-title {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 500px;
  /* background-color: rgba(248, 249, 250, 1); */
  background-color: var(--integrity);

  height: max-content;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard-title-hidden {
  height: 0px;
  transition: 0.5s ease all;
}

.dashboard-panel-hidden-title:hover .dashboard-title-hidden {
  display: flex;
  height: 24px;
  transition: 0.5s ease all;

  color: var(--collaboration1);
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
  z-index: 1000;
}

.dashboard-title-hidden .display-4 {
  font-size: 15px;
  margin-bottom: 0px;
}

.dashboard-title-hidden:hover {
  cursor: pointer;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: linear-gradient(
    135deg,
    rgb(228, 97, 15) 0%,
    rgb(228, 144, 92) 100%
  );
}

.dashboard-title-hidden:hover .display-4 {
  color: white;
}
