.post-contract-slider-ticks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 280px;
  margin-left: 10px;
  margin-top: 5px;

  font-size: 10px;
}

.post-contract-slider-ticks-highlight {
  color: var(--heritage);
}

.pcst-tick {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--collaboration1);
  cursor: pointer;
}

.pcst-tick-highlight {
  width: 3px;
  height: 10px;
  background-color: var(--heritage);
}

.post-contract-slider-ticks > .pcst-tick > .pcra-status-summary {
  position: absolute;
  margin-top: 15px;
  margin-left: -100px;
  z-index: 10000; /*added to bring pcra summary to front*/
}
