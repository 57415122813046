.post-contract-stages-control {
  display: flex;
  flex-direction: column;

  margin: 15px;
}

.project-stages-post-contract-control-label {
  width: 100px;
  /* width: auto; */
  justify-content: flex-start;
}

.project-stages-post-contract-duration-input {
  width: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.project-stages-month-select {
  width: 120px;
  height: 30px;
  margin-left: 10px;
}

.post-contract-stages-row {
  display: flex;
  flex-direction: row;

  width: 310px;
}
