.pcu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 500px;
  padding: 15px;
}

.pcu-select-list {
  width: 100%;
  height: 25px;
}

.pcu-error-container {
  padding-top: 5px;
  width: 400px;
  display: flex;
  justify-content: center;
}

.pcu-select-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.pcu-container .general-row-container {
  height: max-content;
  min-height: 30px;
}
