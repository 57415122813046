.pcct {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pcct-percent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 10px;
}

.pcct-percent {
  color: var(--heritage);
  font-size: 20px;

  margin-right: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pcct-text {
  font-size: 14px;
}

.pcct-horizontal-chart {
  width: 450px;
  height: 100px;
}
