.project-team {
  width: 500px;

  height: 103px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-profile {
  width: 250px;

  align-items: center;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
}

.project-profile-image {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: transform 0.5s;

  width: 80px;
  height: 80px;
  border-radius: 100px;
}

.project-profile-image:hover {
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
  cursor: pointer;
}

.dashboard-profile-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-profile-name {
  font-size: 14px;
}

.project-profile-role {
  font-size: 11px;
  color: var(--heritage);
}

.project-profile-details {
  display: flex;
  flex-direction: column;
}

.project-team-members-link{
  display:flex;
  align-self: end;
  padding-right: 10px;
  font-size:14px;
}
.project-team-members-link:hover{
  cursor: pointer; 
  color:var(--heritage);
}